import { render, staticRenderFns } from "./SearchMassManagementPage.html?vue&type=template&id=63cf55e4&scoped=true&"
import script from "./SearchMassManagementPage.vue?vue&type=script&lang=js&"
export * from "./SearchMassManagementPage.vue?vue&type=script&lang=js&"
import style0 from "./SearchMassManagementPage.scss?vue&type=style&index=0&id=63cf55e4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63cf55e4",
  null
  
)

export default component.exports