<template src="./SearchMassManagementPage.html"></template>
<style scoped lang="scss" src="./SearchMassManagementPage.scss"></style>

<script>
import { OrderListMixin } from '@/mixins'
const LimitFilter = () => import('@/components/limit-filter/LimitFilter')
const FilterOrderForm = () => import('@/components/form/filter-order-form/FilterOrderForm')
const TotalOrder = () => import("@/components/total-order/TotalOrder");

export default {
  name: 'SearchMassManagementPage',
  mixins:  [OrderListMixin],
  components: { LimitFilter, TotalOrder, FilterOrderForm },
  data: function () {
    return {
      table: {
        fields: [
          { isSortAble: 'custom' , prop: 'category', label: 'カテゴリ', width: '150' },
          { isSortAble: 'custom' , prop: 'body', label: 'ボディ', width: '120' },
          { isSortAble: 'custom' , prop: 'brand', label: '会社名', width: '200' },
          { isSortAble: 'custom' , prop: 'factory', label: '発注工場', width: '200' },
          { isSortAble: 'custom' , prop: 'importer', label: '担当者', width: '200' },
          { isSortAble: 'custom' , prop: 'content', label: '案件内容', width: '300' },
          { isSortAble: 'custom' , prop: 'orderDate', label: '発注日', width: '120' },
          { isSortAble: 'custom' , prop: 'classify', label: '区分', width: '200' },
          { isSortAble: 'custom' , prop: 'code', label: '量産品番', width: '120' },
          { isSortAble: 'custom' , prop: 'size', label: 'サイズ', width: '150' },
          { isSortAble: 'custom' , prop: 'color', label: 'カラー', width: '150' },
          { isSortAble: 'custom' , prop: 'quantity', label: '発注数量', width: '180' },
          { isSortAble: 'custom' , prop: 'unitPrice', label: '単価 (¥)', width: '120' },
          { isSortAble: 'custom' , prop: 'fee', label: '版代（¥）', width: '200' },
          { isSortAble: 'custom' , prop: 'total', label: '小計（税抜）', width: '200' },
          { isSortAble: 'custom' , prop: 'actualQuantity', label: '入荷数量', width: '200' },
          { isSortAble: 'custom' , prop: 'deliveryDate', label: '納品日', width: '120', class: 'text-center' },
          { isSortAble: 'custom' , prop: 'formCode', label: '伝票No.', width: '200' },
          { isSortAble: 'custom' , prop: 'paymentCode', label: '請求書No.', width: '200' },
          { isSortAble: 'custom' , prop: 'specDate', label: '仕様書送信日', width: '120' },
          { isSortAble: 'custom' , prop: 'file', label: '発注書ファイル名', width: '200' },
          { isSortAble: 'custom' , prop: 'note', label: '備考欄 (お客様共有)', width: '300', class: 'col-note' },
          { isSortAble: 'custom' , prop: 'internalNote', label: '社内メモ', width: '300', class: 'col-note' },
          { isSortAble: 'custom' , prop: 'updatedAt', label: '更新日', width: '120', class: 'col-updated' },
        ],
        items: [],
        total: 0
      }
    }
  },
  created() {
    if (this.isCompany) this.table.fields = [
      { isSortAble: 'custom' , prop: 'category', label: 'カテゴリ', width: '150' },
      { isSortAble: 'custom' , prop: 'body', label: 'ボディ', width: '120' },
      { isSortAble: 'custom' , prop: 'importer', label: '担当者', width: '200' },
      { isSortAble: 'custom' , prop: 'orderDate', label: '発注日', width: '120' },
      { isSortAble: 'custom' , prop: 'classify', label: '区分', width: '200' },
      { isSortAble: 'custom' , prop: 'code', label: '量産品番', width: '120' },
      { isSortAble: 'custom' , prop: 'size', label: 'サイズ', width: '150' },
      { isSortAble: 'custom' , prop: 'color', label: 'カラー', width: '150' },
      { isSortAble: 'custom' , prop: 'quantity', label: '発注数量', width: '180' },
      { isSortAble: 'custom' , prop: 'actualQuantity', label: '入荷数量', width: '200' },
      { isSortAble: 'custom' , prop: 'deliveryDate', label: '納品日', width: '120', class: 'text-center' },
      { isSortAble: 'custom' , prop: 'formCode', label: '伝票No.', width: '200' },
      { isSortAble: 'custom' , prop: 'paymentCode', label: '請求書No.', width: '200' },
      { isSortAble: 'custom' , prop: 'note', label: '備考欄 (お客様共有)', width: '300', class: 'col-note' },
    ]
  },
  methods: {
    clickToCreateOrderButton() {
      this.$router.push({ name: 'routes.massManagement.create' })
    },
    clickToEditButton(orderIndex) {
      const item = this.table.items.find((item, index) => index === orderIndex) || null
      if (!item || !item.id) return

      this.$router.push({ name: 'routes.massManagement.edit', params: { id: item.id } })
    },
  }
}
</script>
